import { SystemProps, x } from '@xstyled/styled-components';
import { EventWithScoresAndOdds } from 'interfaces/events';
import React, { ReactNode } from 'react';
import UpcommingEventCard from './UpcomingEventCard';

type EventScoreList = {
    hideTitle?: boolean;
    events: EventWithScoresAndOdds[];
    showPredictions?: boolean;
    onBetSimulate?: boolean;
    selectedEvent?: string;
    selectEvent?: (x: string) => void;
} & SystemProps;
function UpcomingEventScoreList({ events, showPredictions }: EventScoreList) {
    // Split the events into groups of dates

    return (
        <x.div
            w='100%'
            style={{
                display: 'flex',
                flexDirection: 'row',
                flexWrap: 'wrap',
                justifyContent: 'space-around',
                alignItems: 'center',
            }}
        >
            <RowWrapper>
                {events.map((e, idx) => (
                    <x.div
                        key={`esc-${idx}`}
                        minWidth={'23em'}
                        maxWidth='33.33%'
                        px={'.51em'}
                        flex='0 0 auto'
                    >
                        <UpcommingEventCard
                            mr={{ _: 'auto', md: 'auto' }}
                            event={e}
                            showPredictions={showPredictions}
                        />
                    </x.div>
                ))}
            </RowWrapper>
        </x.div>
    );
}

const RowWrapper = (props: { children?: ReactNode }) => {
    return (
        <x.div
            className='custom-scroll'
            px={3}
            display='flex'
            justifyContent='space-around'
        >
            {props.children}
        </x.div>
    );
};

export default UpcomingEventScoreList;
