import { x } from '@xstyled/styled-components';
import PageSection from 'components/common/PageSection';
import { BookDef } from 'interfaces/sportbook';
// import { useRouter } from 'next/router';
import React from 'react';

type OwnProps = {
    books: BookDef[];
};
function SportsBooks({ books }: OwnProps) {
    // const router = useRouter();
    return (
        <PageSection
            className='download-area-bottom'
            title={`Top US <span>Sportsbooks</span>`}
        >
            <x.div
                w={1}
                display='flex'
                flexDirection={{ _: 'column', md: 'row' }}
                alignItems='stretch'
                justifyContent='space-between'
            >
                {books.map((b, bIdx) => (
                    <x.div
                        flex={1}
                        key={`aff-${bIdx}`}
                        mx={2}
                        mb={{ _: 8, md: 0 }}
                        paddingBottom={'1em'}
                        border='1px solid #000'
                        borderRadius='4px'
                    >
                        <x.div position='relative' h='100%'>
                            <h4>
                                <x.div
                                    h={24}
                                    background={`url(${b.urlToImage})`}
                                    backgroundPosition='center'
                                    backgroundSize='contain'
                                    backgroundRepeat='no-repeat'
                                />
                            </h4>
                            {b.rating ? (
                                <x.h4 textAlign={'center'}>
                                    <i
                                        style={{ color: '#f7931e' }}
                                        className='fas fa-star'
                                    ></i>
                                    {b.rating}/5
                                </x.h4>
                            ) : null}

                            <x.a
                                background='none'
                                border='none'
                                margin='auto'
                                borderBottom='3px solid #f7931e'
                                display='flex'
                                alignItems='center'
                                justifyContent='center'
                                href={b.site}
                                w='5.5em'
                                target='_blank'
                                fontWeight='700'
                            >
                                Go to Site
                            </x.a>
                        </x.div>
                    </x.div>
                ))}
            </x.div>
        </PageSection>
    );
}

export default SportsBooks;
