import { useDown } from '@xstyled/styled-components';
import { useEffect, useState } from 'react';

function useSmallScreen(smallBreak: string = 'sm') {
    const [small, setSmall] = useState<boolean>(false);

    const bpDown = useDown(smallBreak);
    useEffect(() => {
        setSmall(bpDown === true);
    }, [bpDown]);

    return small;
}

export default useSmallScreen;
