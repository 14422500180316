import PageSection from 'components/common/PageSection';
import React from 'react';

function GettingStarted() {
    return (
        <PageSection
            className='progress-area'
            title={`Getting Started is <span>Easy</span>`}
        >
            <div className='col-md-12'>
                <div className='proccess'>
                    <ul>
                        <li>
                            <div className='single-proccess-box'>
                                <div className='icon'>
                                    <i className='fas fa-user-plus'></i>
                                </div>
                                <h4>Sign Up</h4>
                                <div className='right-arrow'>
                                    <img src='/img/line-arrow.png' alt='' />
                                </div>
                            </div>
                        </li>
                        <li>
                            <div className='single-proccess-box'>
                                <div className='icon'>
                                    <i className='fas fa-check-circle'></i>
                                </div>
                                <h4>Make Your Picks</h4>
                                <div className='right-arrow'>
                                    <img src='/img/line-arrow.png' alt='' />
                                </div>
                            </div>
                        </li>
                        <li>
                            <div className='single-proccess-box'>
                                <div className='icon'>
                                    <i className='fas fa-trophy'></i>
                                </div>
                                <h4>Win</h4>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
        </PageSection>
    );
}

export default GettingStarted;
