import { x } from '@xstyled/styled-components';
import moment from 'moment';
import React from 'react';
import { Col } from 'react-bootstrap';
import { NewsArticle } from 'services/external/news/types';

type ItemProps = NewsArticle & { main?: boolean };
export default function NewsStory({
    title,
    publishedAt,
    url,
    urlToImage,
    main,
    sportName,
}: ItemProps) {
    return (
        <>
            {main ? (
                <x.div marginBottom={'1em'}>
                    <x.div
                        backgroundImage={`linear-gradient(to bottom, rgba(0,0,0,0), rgba(0,0,0,1)), url(${urlToImage})`}
                        backgroundSize='contain, cover'
                        backgroundPosition='center, center'
                        borderRadius='3px'
                        h='xs'
                        w='100%'
                        marginBottom='0.5em'
                    ></x.div>
                    <x.a href={url}>
                        <x.h5>{title}</x.h5>
                    </x.a>
                    <x.span
                        background={'#f7931eb8'}
                        color='#000'
                        px='5px'
                        borderRadius='2px'
                        py='1px'
                        fontSize='12px'
                    >
                        {sportName}
                    </x.span>
                    <x.span
                        background='#e2e2e2'
                        px='5px'
                        ml='1em'
                        borderRadius='2px'
                        py='1px'
                        fontSize='12px'
                    >
                        <i className='far fa-clock text-muted'></i>{' '}
                        {moment(publishedAt).fromNow()}
                    </x.span>
                </x.div>
            ) : (
                <x.div
                    display='flex'
                    flexWrap='wrap'
                    w='100%'
                    marginBottom={'1em'}
                >
                    <Col className='col-article' sm={9}>
                        <x.a href={url}>
                            <x.h6>{title}</x.h6>
                        </x.a>
                        <x.span
                            background={'#f7931eb8'}
                            color='#000'
                            px='5px'
                            borderRadius='2px'
                            py='1px'
                            fontSize='12px'
                        >
                            {sportName}
                        </x.span>
                        <x.span
                            background='#e2e2e2'
                            px='5px'
                            ml='1em'
                            borderRadius='2px'
                            py='1px'
                            fontSize='12px'
                        >
                            <i className='far fa-clock text-muted'></i>{' '}
                            {moment(publishedAt).fromNow()}
                        </x.span>
                    </Col>
                    <Col className='col-article' sm={3}>
                        <x.div
                            backgroundImage={`linear-gradient(to bottom, rgba(0,0,0,0), rgba(0,0,0,1)), url(${urlToImage})`}
                            backgroundSize='contain, cover'
                            backgroundPosition='center, center'
                            borderRadius='3px'
                            h='6em'
                            w='100%'
                        ></x.div>
                    </Col>
                </x.div>
            )}
        </>
    );
}
