import styled, { x } from '@xstyled/styled-components';
import NewsStory from 'components/common/NewsStory';
import PageSection from 'components/common/PageSection';
import React, { useMemo } from 'react';
import { Col } from 'react-bootstrap';
import { NewsArticle } from 'services/external/news/types';

type OwnProps = { title: string; articles: NewsArticle[] };
function TopStories({ title, articles }: OwnProps) {
    const featuredNews = useMemo(() => {
        return articles.filter((a) => !!a.urlToImage)[0] || articles[0];
    }, [articles]);
    const otherNews = useMemo(() => {
        return articles.filter((a) => a.title != featuredNews.title);
    }, [featuredNews]);

    return (
        <StyledSection title={title}>
            <Col className='col-article' md={6} lg={4}>
                <x.div my={{ _: 0, md: 4 }}>
                    <NewsStory main={true} {...featuredNews} />
                </x.div>
            </Col>

            <Col className='col-article d-flex flex-wrap px-0' md={6} lg={8}>
                {otherNews.map((a, idx) => (
                    <x.div
                        my={{ _: 0, md: 4 }}
                        flex={{
                            _: '1 0 calc(100% - 10px - 1em)',
                            lg: '1 0 calc(50% - 10px - 1em)',
                        }}
                        key={`top-stories-${idx}`}
                        marginRight='1em'
                    >
                        <NewsStory {...a} />
                    </x.div>
                ))}
            </Col>
        </StyledSection>
    );
}

const StyledSection = styled(PageSection)`
    .content-row {
        align-items: stretch;
    }
`;

export default TopStories;
