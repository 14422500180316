import { EventWithScoresAndOdds } from 'interfaces/events';
import useSWR from 'swr';
import { REQUEST_HEADER_MST_TZ } from 'utils/constants/requests';

const fetcher = async (url: string) => {
    const response = await (
        await fetch(`${url}`, {
            headers: {
                [REQUEST_HEADER_MST_TZ]:
                    Intl.DateTimeFormat().resolvedOptions().timeZone,
            },
        })
    ).json();
    return response;
};

function useUpCommingMatchUps() {
    const { error, data: { events, restriction } = {} } = useSWR<{
        events: EventWithScoresAndOdds[];
        restriction?: string;
    }>('/api/up-comming-matches?inc=picks,odds', {
        fetcher,
        refreshInterval: undefined,
        revalidateOnMount: true,
        revalidateOnFocus: false,
    });

    return {
        error,
        events,
        restriction,
        loading: !error && !events,
    };
}

export default useUpCommingMatchUps;
