import styled, { SystemProps, x } from '@xstyled/styled-components';
import Typography from 'components/common/Typography';
import { Records } from 'database/models/SportSchedule';
// import useEventTime from 'hooks/useEventTime';
import useSmallScreen from 'hooks/useSmallScreen';
import { EventWithScoresAndOdds, TeamWithScore } from 'interfaces/events';
// import { Moment } from 'moment-timezone';
import React, { useMemo } from 'react';
import {
    EVENT_STATUS_FINAL,
    EVENT_STATUS_IN_PROGRESS,
    // EVENT_STATUS_TEXT,
} from 'utils/constants/leagues';
import { useRouter } from 'next/router';
import { ISportBook } from 'database/models/SportBook';
import { IEventOdds } from 'database/models/EventOdds';

type LineType = Omit<Omit<IEventOdds, 'affialiateId'>, 'affiliateName'> & {
    affiliate: ISportBook;
};
type HeaderProps = {
    showPredictions?: boolean;
};
function CardHeader({ showPredictions }: HeaderProps) {
    return (
        <tr>
            <x.th w={'10%'} pr={4}></x.th>

            {showPredictions ? (
                <>
                    {/* <x.th w={perPeriodsWidth / 2}>
                        <Typography textAlign='center' variant='caption'>
                            {tiny ? 'Pick' : 'Our Pick'}
                        </Typography>
                    </x.th>
                    {showChance ? (
                        <x.th w={perPeriodsWidth / 2}>
                            <Typography textAlign='center' variant='caption'>
                                {tiny ? '%' : 'Winning %'}
                            </Typography>
                        </x.th>
                    ) : null} */}
                    <x.th textAlign='center' w={'10%'}></x.th>
                    <x.th textAlign='center' w={'20%'}></x.th>
                </>
            ) : null}
        </tr>
    );
}

type DataProps = {
    team: TeamWithScore;
    isWinner?: boolean;
    hasScore?: boolean;
    concise: boolean;
    willWin?: boolean;
    winChance?: number;
    showChance: boolean;
    hasPredictions?: boolean;
    showPredictions?: boolean;
    periodScores: {
        won: boolean;
        score: string;
    }[];
    records?: Records;
    tType: string;
    line: LineType | undefined | null;
};
function TeamData({ team, winChance, hasPredictions, tType, line }: DataProps) {
    const displayMLOd = () => {
        const odd =
            (tType == 'home' ? line?.spread?.home : line?.spread?.away) || 0;

        return odd > 0 ? `+${odd}` : odd;
    };

    const displayML = () => {
        const odd =
            (tType == 'home' ? line?.spread?.home : line?.spread?.away) || 0;

        return odd > 0
            ? `${((100 / (odd + 100)) * 100).toFixed(1)}%`
            : `${((Math.abs(odd) / (Math.abs(odd) + 100)) * 100).toFixed(1)}%`;
    };

    return (
        <x.tr>
            <x.td pr={4}>
                <x.div
                    display='flex'
                    alignItems='center'
                    justifyContent='start'
                >
                    <x.img
                        w={6}
                        h={6}
                        src={team.badgeTiny || '/img/mst-logo.svg'}
                        mr='3px'
                    />
                    <Typography
                        fontSize='title'
                        fontWeight='bold'
                        color='black'
                    >
                        {team.abbreviation}
                    </Typography>
                </x.div>
            </x.td>
            <x.th textAlign='center' w={2}>
                <Typography variant='body' fontWeight='bold' margin={'.5em'}>
                    {hasPredictions
                        ? winChance !== undefined
                            ? `${(winChance * 100).toFixed(2)}%`
                            : '-'
                        : displayML()}
                </Typography>
            </x.th>
            <x.th textAlign='center' w={1}>
                <x.div
                    border={'1px solid black'}
                    w={'100%'}
                    margin={'.5em'}
                    borderRadius='3px'
                    display='flex'
                    flexWrap='wrap'
                >
                    <Typography
                        color='#000'
                        variant='body'
                        fontWeight='bold'
                        w={'50%'}
                    >
                        {displayMLOd()}
                    </Typography>
                    <x.div
                        borderLeft={'1px solid black'}
                        display='flex'
                        justifyContent='center'
                        alignItems='center'
                        w='50%'
                    >
                        <x.img
                            w={'auto'}
                            h={6}
                            src={
                                line?.affiliate.urlToImage ||
                                '/img/mst-logo.svg'
                            }
                        />
                    </x.div>
                </x.div>
            </x.th>
        </x.tr>
    );
}

type EventScoreCardProps = SystemProps & {
    event: EventWithScoresAndOdds;
    showPredictions?: boolean;
};
export default function UpcomingEventCard({
    event,
    showPredictions,
    ...props
}: EventScoreCardProps) {
    const router = useRouter();
    const matchUpUrl = `/sport/${event.sportKey?.toLocaleLowerCase()}/match-up?eventId=`;
    const { teams, status, prediction, homeTeamId, awayTeamId } = event;
    const concise = useSmallScreen('md');
    let winnerTeamId: string | undefined;

    const hasScore = Boolean(
        status &&
            [EVENT_STATUS_FINAL, EVENT_STATUS_IN_PROGRESS].includes(status)
    );

    // Always show the away team first
    const sortedTeams = useMemo(
        () =>
            Object.entries(teams).sort(([aKey]) =>
                aKey.toLowerCase() === 'away' ? -1 : 1
            ),
        [teams]
    );

    if (hasScore) {
        winnerTeamId =
            teams.away.score.total > teams.home.score.total
                ? awayTeamId
                : homeTeamId;
    }

    return (
        <x.div
            position='relative'
            my={4}
            padding={4}
            {...props}
            w='100%'
            border={'1px solid black'}
            borderRadius={4}
            display='flex'
            flexWrap={'wrap'}
        >
            {/* {event.restricted ? <Overlay /> : null} */}
            <StyledTable>
                <thead>
                    <CardHeader showPredictions={showPredictions} />
                </thead>
                <tbody>
                    {sortedTeams.map(([key, t], idx) => (
                        <React.Fragment key={`td-${idx}`}>
                            {/* Add a spacer between rows */}
                            <TeamData
                                concise={concise}
                                team={t}
                                hasScore={hasScore && event.restricted !== true}
                                hasPredictions={
                                    prediction !== undefined &&
                                    event.restricted !== true
                                }
                                showPredictions={showPredictions}
                                winChance={
                                    prediction &&
                                    prediction.winner &&
                                    prediction.probability &&
                                    prediction.probability > 0
                                        ? prediction.winner.toLowerCase() ===
                                          key.toLowerCase()
                                            ? prediction.probability
                                            : 1 - prediction.probability
                                        : undefined
                                }
                                showChance={Boolean(
                                    prediction &&
                                        prediction.winner &&
                                        prediction.probability > 0
                                )}
                                willWin={
                                    prediction && prediction.winner
                                        ? prediction.winner.toLowerCase() ===
                                          key.toLowerCase()
                                        : undefined
                                }
                                isWinner={winnerTeamId === t.externalId}
                                tType={key}
                                line={
                                    event.lines?.length ? event.lines[0] : null
                                }
                                periodScores={[]}
                            />
                        </React.Fragment>
                    ))}
                </tbody>
            </StyledTable>

            <x.button
                background='none'
                border='none'
                margin='auto'
                borderBottom='3px solid #f7931e'
                display='flex'
                alignItems='center'
                justifyContent='center'
                w='auto'
                lineHeight='0.5'
                paddingTop='20px'
                onClick={() => router.push(`${matchUpUrl}${event.externalId}`)}
            >
                <x.h4 marginBottom='0px'>Matchup Stats</x.h4>
            </x.button>
        </x.div>
    );
}

const StyledTable = styled.table`
    height: 100%;
    width: 100%;
    table-layout: fixed;
`;

// const Overlay = styled.div`
//     position: absolute;
//     top: 0;
//     bottom: 0;
//     left: 0;
//     right: 0;

//     background-color: accentPrimary;

//     z-index: 2;

//     backdrop-filter: blur(5px);
//     background-size: cover;
//     opacity: 0.85;
// `;
