import PageSection from 'components/common/PageSection';
import UpcomingEventScoreList from 'components/events/UpcomingEventScoreList';
import { EventWithScoresAndOdds } from 'interfaces/events';

type EventScoreListType = {
    events: EventWithScoresAndOdds[];
};
export default function UpCommingMatchUps({ events }: EventScoreListType) {
    return (
        <PageSection title={`UPCOMING <span>MATCHUPS</span>`}>
            <UpcomingEventScoreList events={events} showPredictions />
        </PageSection>
    );
}
